import React from "react";

export default function Header() {
  return (
    <div>
      <div className="col" style={{ width: "15rem", margin: "0 auto" }}>
        <img
          src="/images/me.jpg"
          alt="Vipul Sharma"
          style={{
            borderStyle: "none",
            borderRadius: "15rem",
          }}
        />
      </div>
      <div>
        <h1 style={{ margin: "0" }}>Vipul Sharma</h1>
        <h4>Here's an interesting collection of all my projects.</h4>
      </div>
    </div>
  );
}
