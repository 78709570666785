import React from "react";

export default function Project({ project }) {
  return (
    <div
      className="card xs-10 sm-8 md-5 lg-3"
      style={{ margin: "2rem 1rem", textAlign: "center" }}
    >
      <div>
        <img src={project.image} alt={`${project.name}`} />
      </div>

      <div className="padding" style={{ textAlign: "left" }}>
        <div className="card-body">
          <p className="card-title padding-bottom">
            <strong>{project.name}</strong>
          </p>
          <p className="text-primary">{project.description}</p>
          <div>
            <div>
              {project?.tags.map((tag, index) => (
                <p
                  key={index}
                  className="badge secondary margin-right"
                  style={{}}
                >
                  {tag}
                </p>
              ))}
            </div>

            <div>
              {project.buttons.map(({ label, link }, index) => (
                <a
                  key={index}
                  href={link}
                  style={{ backgroundImage: "none", margin: "0" }}
                >
                  <button className="margin-right btn-secondary-outline">
                    {label}
                  </button>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
