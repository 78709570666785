const projects = [
  //   {
  //     name: "uni-cards",
  //     description: "Homepage clone of uni.cards.",
  //     image: "/images/projects/uni-cards.jpg",
  //     tags: ["React", "Advance CSS", "Mobile First Design"],
  //     buttons: [
  //       { label: "Website", link: "https://uni-cards.qwertyvipul.io/" },
  //       { label: "GitHub", link: "https://github.com/qwertyvipul/uni-cards" },
  //     ],
  //   },
  {
    name: "atlan-fe",
    description:
      "Browser SQL queries and render results in grid with features.",
    image: "/images/projects/atlan-fe.jpg",
    tags: ["React", "React Data Grid"],
    buttons: [
      { label: "Website", link: "https://atlan-fe.qwertyvipul.io/" },
      { label: "GitHub", link: "https://github.com/qwertyvipul/atlan-fe" },
      {
        label: "Lucidchart",
        link: "https://lucid.app/lucidchart/75d0fab6-2f30-4fc9-a156-71f42374e7be/view",
      },
    ],
  },
  {
    name: "qwertypy",
    description:
      "Python utilities library for financial utilities, data analysis, visualization and DSA.",
    image: "/images/projects/qwertypy.jpg",
    tags: ["Python"],
    buttons: [
      { label: "PyPi", link: "https://pypi.org/project/qwertypy/" },
      { label: "Docs", link: "https://qwertyvipul.io/qwertypy/" },
      { label: "GitHub", link: "https://github.com/qwertyvipul/qwertypy" },
    ],
  },
  {
    name: "jsdoc.github.io",
    description: "Open source: JSDoc 3 website migration",
    image: "/images/projects/jsdoc.jpg",
    tags: ["Open Source", "React"],
    buttons: [
      {
        label: "Website",
        link: "https://jsdoc.qwertyvipul.io/about-getting-started",
      },
      {
        label: "PR",
        link: "https://github.com/jsdoc/jsdoc.github.io/pull/237",
      },
    ],
  },
  {
    name: "qwertyvipul.io",
    description: "My Website",
    image: "/images/projects/qwertyvipul.jpg",
    tags: ["React"],
    buttons: [
      {
        label: "Website",
        link: "https://qwertyvipul.io/",
      },
    ],
  },
  {
    name: "qwiz",
    description: "Take and review quiz.",
    image: "/images/projects/qwiz.jpg",
    tags: ["React"],
    buttons: [
      { label: "Website", link: "https://qwiz.qwertyvipul.io/" },
      { label: "GitHub", link: "https://github.com/qwertyvipul/qwiz" },
    ],
  },
  {
    name: "upforce-stepper",
    description: "Material-UI based multi-page form.",
    image: "/images/projects/upforce-stepper.jpg",
    tags: ["React", "Material-UI"],
    buttons: [
      { label: "Website", link: "https://qwertyvipul.io/upforce-stepper/" },
      {
        label: "GitHub",
        link: "https://github.com/qwertyvipul/upforce-stepper",
      },
    ],
  },
  {
    name: "smart_rail",
    description: "Smart India Hackathon - 2018",
    image: "/images/projects/smart-rail.jpg",
    tags: ["PHP"],
    buttons: [
      {
        label: "PPT",
        link: "https://github.com/qwertyvipul/smart_rail/blob/master/ppt/Black_Caps_Smart_Rail.pptx",
      },
      {
        label: "GitHub",
        link: "https://github.com/qwertyvipul/smart_rail",
      },
    ],
  },
  {
    name: "elevator",
    description: "C++ Elevator Simulation",
    image: "/images/projects/elevator.jpg",
    tags: ["C++"],
    buttons: [
      {
        label: "GitHub",
        link: "https://github.com/qwertyvipul/elevator",
      },
    ],
  },
];

export default projects;
