import React from "react";
import Project from "./Project";
import projects from "../data/projects";

export default function Projects() {
  return (
    <div
      className="row flex flex-spaces flex-top"
      style={{ textAlign: "center" }}
    >
      {projects.map((project, index) => (
        <Project key={index} project={project} />
      ))}
    </div>
  );
}
